<template>
  <div>
    <div v-if="isMobileOrTablet" class="d-flex overflow-auto">
      <c-btn
        v-for="(item, index) in items"
        :key="item.value"
        :class="[
          'ml-1 mb-4',
          { 'ml-4': item.startGroup && index !== 0 },
          { 'primary white--text': item.value === currentGroup }
        ]"
        height="32"
        depressed
        @click="currentGroup = item.value"
      >
        <v-icon v-if="item.icon" size="24" :class="['primary--text mr-2', { 'white--text': item.value === currentGroup }]">
          ${{ item.icon }}
        </v-icon>
        {{ item.label }}
      </c-btn>
    </div>
    <v-tabs
      v-else
      v-model="currentGroup"
      show-arrows
      prev-icon="$arrow-left"
      next-icon="$arrow-right"
      grow
      height="32"
    >
      <c-btn
        v-for="(item, index) in items"
        :key="item.value"
        :class="[
          'ml-1 black--text font-weight-regular',
          { 'ml-4': item.startGroup && index !== 0 },
          { 'primary white--text': item.value === currentGroup }
        ]"
        height="32"
        depressed
        @click="currentGroup = item.value"
      >
        <v-icon v-if="item.icon" size="24" :class="['primary--text mr-2', { 'white--text': item.value === currentGroup }]">
          ${{ item.icon }}
        </v-icon>
        {{ item.label }}
      </c-btn>
    </v-tabs>
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'GroupBySelect',
    components: { CBtn },
    props: {
      items: {
        type: Array,
        required: true
      },
      value: {
        type: String,
        required: true
      }
    },
    computed: {
      isMobileOrTablet() {
        return this.$vuetify.breakpoint.smAndDown
      },
      currentGroup: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('change-group', val)
        }
      }
    }
  }
</script>
