<template>
  <c-autocomplete
    :value="campaignStatuses"
    :items="mappedStatusesByLocale"
    item-text="label"
    item-value="value"
    :label="$t('campaign_statistics.campaign_statuses')"
    :placeholder="$t('campaign_statistics.select_statuses')"
    clearable
    multiple
    hide-details
    :search-input="search"
    @update:search-input="setSearch($event)"
    @change="changeCampaignStatuses($event)"
  />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CAutocomplete from '@clickadilla/components/ui/CAutocomplete.vue'

  export default {
    name: 'CampaignStatuses',
    components: { CAutocomplete },
    data() {
      return {
        search: ''
      }
    },
    computed: {
      ...mapState('campaigns', ['statuses']),
      ...mapState('campaignsStatistics', ['campaignStatuses']),
      mappedStatusesByLocale() {
        return this.statuses.map((status) => ({
          ...status,
          label: this.$t(`main.statuses.${status.value}`)
        }))
      }
    },
    methods: {
      ...mapActions('campaignsStatistics', ['setCampaignStatuses']),
      changeCampaignStatuses(campaignStatuses) {
        this.setCampaignStatuses(campaignStatuses)
        this.setSearch('')
        this.$emit('update')
      },
      setSearch(value) {
        this.search = value
      }
    }
  }
</script>
