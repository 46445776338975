<template>
  <div class="white pb-4 global-relative overflow-hidden">
    <settings :campaign-ids="campaignIds" class="mb-11 mb-sm-7 mb-lg-5" />
    <v-overlay
      v-if="!isLoading && !campaignsTotal"
      absolute
      opacity="0.95"
      color="white"
      class="d-flex align-start mt-16 mx-3 pt-11"
    >
      <v-card class="text-center d-flex flex-column align-center mt-16 pt-16" color="white" elevation="0" max-width="270">
        <v-icon
          class="mx-auto"
          size="32"
          color="primary"
        >
          $information
        </v-icon>
        <div class="text-center font-weight-medium text-subtitle-1 black--text mt-2">
          {{ $t('campaign_statistics.empty_campaign.empty_campaign_title') }}
        </div>
        <c-btn
          color="primary"
          height="46"
          x-large
          class="text-subtitle-1 font-weight-medium mt-9"
          :label="$t('campaign_statistics.empty_campaign.create_new_campaign')"
          @click="redirectToCreateCampaign()"
        />
        <div class="secondary-darken--text text-center text-subtitle-1 mt-3">
          {{ $t('campaign_statistics.empty_campaign.create_campaign_now') }}
        </div>
      </v-card>
    </v-overlay>
    <div class="border-secondary rounded mx-4">
      <v-card elevation="0" class="mb-16">
        <v-card-title
          class="text-body-1 secondary-lighten font-weight-medium black-text py-3 pl-5 mb-6"
        >
          {{ $t('campaign_statistics.available_parameters') }}
        </v-card-title>
        <v-card-text class="pa-0 px-3 px-sm-5 px-md-0">
          <group-by class="mb-9 mb-md-10" />
          <chart />
        </v-card-text>
      </v-card>

      <statistics-table class="pt-8" />
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import GroupBy from './GroupBy.vue'
  import Chart from './Chart.vue'
  import Settings from './Settings.vue'
  import StatisticsTable from './StatisticsTable.vue'
  import gtmPush from '@/services/utils/gtm-push.js'
  import routeNames from '@/types/route-names.js'
  import { dashboardRepository } from '@/services/repository-factory.js'
  import handleErrors from '@/services/handleErrors.js'

  export default {
    name: 'CampaignsStatistics',
    components: {
      CBtn,
      GroupBy,
      Chart,
      Settings,
      StatisticsTable
    },
    data() {
      return {
        campaignIds: [],
        campaignsTotal: null,
        isLoading: false
      }
    },
    created() {
      this.reset()
      this.fetchDashboard()

      if (this.$route.query.campaigns) {
        this.campaignIds = this.parseIds(this.$route.query.campaigns)
      }

      if (this.$route.query.adFormatIds) {
        this.setAdFormatsIds(this.parseIds(this.$route.query.adFormatIds))
      }

      if (this.$route.query.groupBy) {
        this.setGroupBy(this.$route.query.groupBy)
      }

      this.fetchStatistics()
    },

    methods: {
      ...mapActions('campaignsStatistics', [
        'fetchStatistics',
        'reset',
        'setAdFormatsIds',
        'setGroupBy'
      ]),
      redirectToCreateCampaign() {
        gtmPush({ event: GTM_EVENTS.NO_CAMPAIGN_BUTTON_STATISTICS })
        this.$router.push({ name: routeNames.CREATE_CAMPAIGN })
      },
      parseIds(param) {
        return JSON.parse(param)?.map((id) => Number(id)) ?? []
      },
      async fetchDashboard() {
        this.isLoading = true
        try {
          const response = await dashboardRepository.fetchDashboard()
          this.campaignsTotal = response.campaigns_total
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      }
    }
  }
</script>
