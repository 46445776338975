<template>
  <div class="secondary-lighten py-3 px-3">
    <c-text-field
      v-model="inputStatistics"
      :class="{ 'width-400': isDesktopOrHigher }"
      :placeholder="placeholder"
      single-line
      hide-details
      flat
      clearable
      background-color="white"
      border-color="secondary"
    >
      <template #prepend-inner>
        <v-icon class="mt-1" color="primary" size="20">
          $magnifier
        </v-icon>
      </template>
    </c-text-field>
  </div>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'

  export default {
    name: 'StatisticsSearch',
    components: { CTextField },
    props: {
      value: { type: String, default: '' },
      placeholder: { type: String, default: '' }
    },
    computed: {
      isDesktopOrHigher() {
        return this.$vuetify.breakpoint.mdAndUp
      },
      inputStatistics: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .width-400 {
    max-width: 400px;
  }
</style>
