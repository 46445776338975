<template>
  <v-row align="center" justify="center">
    <v-col cols="12" lg="2" md="3" offset-md="1" offset-lg="2">
      <c-select
        :value="axis.first"
        item-text="text"
        hide-details
        item-value="value"
        :items="filters"
        @change="$emit('change-axis', { name: 'first', value: $event })"
      >
        <template #prepend-inner>
          <v-icon color="info">
            $chart-bar
          </v-icon>
        </template>
      </c-select>
    </v-col>

    <v-col cols="12" lg="2" md="3">
      <c-select
        :value="axis.second"
        item-text="text"
        hide-details
        item-value="value"
        :items="filters"
        @change="$emit('change-axis', { name: 'second', value: $event })"
      >
        <template #prepend-inner>
          <v-icon color="primary">
            $chart-line
          </v-icon>
        </template>
      </c-select>
    </v-col>

    <v-col cols="12" lg="2" md="1">
      <v-progress-circular
        v-show="campaignsStatisticsIsLoading"
        indeterminate
        color="primary"
        width="2"
      />
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'

  export default {
    name: 'StatisticsChartColumns',
    components: {
      CSelect
    },
    props: {
      axis: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapState('campaignsStatistics', ['campaignsStatisticsIsLoading']),
      ...mapGetters('campaignsStatistics', ['selectedGroupByOption']),
      filters() {
        return this.selectedGroupByOption.filters.map(({ value }) => ({
          value,
          text: this.$t(`campaign_statistics.headers.${value}`)
        }))
      }
    },
    watch: {
      selectedGroupByOption(selectedGroupByOption) {
        this.$emit('change-axis', {
          name: 'first',
          value: selectedGroupByOption.filters[0].value
        })
        if (selectedGroupByOption.filters[1]) {
          this.$emit('change-axis', {
            name: 'second',
            value: selectedGroupByOption.filters[1].value
          })
        } else {
          this.$emit('change-axis', {
            name: 'second',
            value: selectedGroupByOption.filters[0].value
          })
        }
      }
    }
  }
</script>
