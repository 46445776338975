<template>
  <c-autocomplete
    :value="adFormatsIds"
    :items="getAdFormatsByAdNetwork"
    item-text="name"
    item-value="id"
    :label="$t('main.ad_format')"
    :placeholder="$t('campaign_statistics.select_ad_format')"
    clearable
    multiple
    hide-details
    :search-input="search"
    @update:search-input="setSearch($event)"
    @change="changeAdFormatsIds($event)"
  />
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CAutocomplete from '@clickadilla/components/ui/CAutocomplete.vue'

  export default {
    name: 'AdFormat',
    components: { CAutocomplete },
    data() {
      return {
        search: ''
      }
    },
    computed: {
      ...mapState('campaignsStatistics', ['adFormatsIds']),
      ...mapGetters('settings', ['getAdFormatsByAdNetwork'])
    },
    methods: {
      ...mapActions('campaignsStatistics', ['setAdFormatsIds']),
      changeAdFormatsIds(adFormatsIds) {
        this.setAdFormatsIds(adFormatsIds)
        this.setSearch('')
        this.$emit('update')
      },
      setSearch(value) {
        this.search = value
      }
    }
  }
</script>
